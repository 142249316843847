<template>
  <section class="sign-in d-flex flex-column">
    <div class="btn-back d-flex" @click="back()">
      <img src="@/assets/icons/Arrow-left.svg" alt="">
      <h6 class="title">Back</h6>
    </div>
    <div class="content">
      <div class="d-flex flex-row align-end justify-between">
        <h3>Become a member</h3>
        <div style="color: #AAAAAA;font-size: 14px">Step 2/2</div>
      </div>
      <h4 class="title">General info</h4>
      <form class="d-flex flex-column">
        <div class="container">
          <label class="input-containers">
            <input type="text" class="street" placeholder="Street*" style="">
          </label>
          <label class="input-containers">
            <input type="text" class="house" placeholder="House № *">
          </label>
        </div>
        <label class="d-flex flex-row align-center justify-between">
          <input type="text" class="post-code" placeholder="Post code *">
          <input type="text" class="city" placeholder="City / Town *">
        </label>
        <label class="d-flex flex-row align-center justify-between">
          <input type="text" class="additional" placeholder="Additional *">
        </label>
        <div class="next-step d-flex">
          <div class="btn-next-step" >
            <h6>Continue</h6>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: "SignUpSecondStep",
  methods:{
    back(){
      this.$router.push('/signUp')
    }
  }
}
</script>

<style scoped lang="scss">
@import "SignUp.scss";
</style>